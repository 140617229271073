*{
  margin:0;
  padding:0;
}
body { min-height: 100vh; background-color: #442ce4!important;}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-thin';
  src: url('./assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-medium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-extraBold';
  src: url('./assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-semiBold';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}
.logo{
  width:100px;
  object-position: center;
}
.bot{
  height: 50px;
  object-position: center;
  align-self: center;

}
#root {
  min-height:  100vh;
}
.backGround-shape{
  
background-repeat: no-repeat;
background-position: center;
background-size: contain;


background-color:#0C045C;
}

.watermark {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url('../public/ZAZÚ-celeste-completo.png'); /* Replace with the actual path to your image */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%; /* Adjust size as needed */
  opacity: 0.04;
  z-index: 1000;
  pointer-events: none;
}
.watermark-menu-small {
  background-size: 50%; /* Smaller watermark */
  opacity: 0.1; /* Slightly more visible watermark */
}
.dashboard-page .watermark {
  display: none;
}
.app-content {
  position: relative;
  z-index: 1;
}

.form__update{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:80%;
  gap:12px;

}

.grid__column{
  display:flex;
  justify-content: center;
  

}
.image__qr{
  width:256px;
  align-self:center;
}
.image__product{
  max-width:100%;
  max-height:100%;
  height:200px;
  object-fit:cover;
  
}
.chart__width{
  width:100%;
}
.chart__height{
  height:100%;
}
.MuiTableCell-root::after {
  content: "";
  position: absolute;
  top: 8px;                    
  bottom: 8px;                 
  right: 0;                    
  width: 1px;
  background-color: #b0b0b0;
}
.logo-container {
  position: fixed;
  bottom: 0; 
  right: 50px;
  background-color: #40B6E9; 
  border-top-left-radius: 19px;
  border-top-right-radius: 19px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}

.fixed-corner-logo {
  width: 72px;
  object-fit: contain;
  height: 72px;
  padding: 7px 7px 10px 7px;
}